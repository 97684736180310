import { Component, OnInit, ViewChild, OnDestroy, Input, ɵConsole } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ImportService } from 'src/app/services/import.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-import-vor',
  templateUrl: './import-vor.component.html',
  styleUrls: ['./import-vor.component.scss']
})
export class ImportVorComponent implements OnInit, OnDestroy {
  @ViewChild('csvReader', null) csvReader: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  errors: any[] = [];
  headers: any[] = [];
  records: any[] = [];
  separator = ';';
  loading = false;
  posted: string;
  filename: string;
  size: number;
  send = false;
  conf = environment.conf_Hyundai;
  responses: any[] = [];

  constructor(
    private importService: ImportService,

  ) {
  }

  ngOnInit() {
    let i = 1;
    let l = 1;
    let line = 0;
    let lineError = false;
    let conf = environment.conf_Hyundai;

    console.log(conf);

    this.dtOptions = {
      language: { url: './assets/dt_internationalization/french.json' },
      // responsive: true,
      pageLength: 25,
      autoWidth: true,
      order: [[0, 'asc']],
      columnDefs: [{
        targets: '_all',
        createdCell(td, cellData, rowData, row, col) {
          $(td).attr('id', i);
          if (row !== line) {
            l++;
            lineError = false;
          }
          if (col > 0 && col <= 9) {
            // VIN
            if (col === conf.VOR[1].column[0].index && cellData.length !== conf.VOR[1].column[0].length) {
              $(td).css('color', 'red');
              $(td).attr('bgcolor', '#E6B0AA');
              $(td).attr('contentEditable', 'true');
              $(td).attr('color', 'red');
              lineError = true;
            }
            //  FUEL_TYPE
            if (col === conf.VOR[1].column[1].index && (cellData < 1 || cellData === '')) {
              $(td).css('color', 'red');
              $(td).attr('bgcolor', '#E6B0AA');
              $(td).attr('contentEditable', 'true');
              $(td).attr('color', 'red');
              lineError = true;
            }

            //  Marque
            if (col === conf.VOR[1].column[2].index && (cellData < 1 || cellData === '')) {
              $(td).css('color', 'red');
              $(td).attr('bgcolor', '#E6B0AA');
              $(td).attr('contentEditable', 'true');
              $(td).attr('color', 'red');
              lineError = true;
            }

            //  origine
            if (col === conf.VOR[1].column[3].index && (cellData < 1 || cellData === '')) {
              $(td).css('color', 'red');
              $(td).attr('bgcolor', '#E6B0AA');
              $(td).attr('contentEditable', 'true');
              $(td).attr('color', 'red');
              lineError = true;
            }

            //  modele
            if (col === conf.VOR[1].column[4].index && (cellData < 1 || cellData === '')) {
              $(td).css('color', 'red');
              $(td).attr('bgcolor', '#E6B0AA');
              $(td).attr('contentEditable', 'true');
              $(td).attr('color', 'red');
              lineError = true;
            }

            //  RENTER CODE
            if (col === conf.VOR[1].column[5].index && (cellData < 1 || cellData === '')) {
              $(td).css('color', 'red');
              $(td).attr('bgcolor', '#E6B0AA');
              $(td).attr('contentEditable', 'true');
              $(td).attr('color', 'red');
              lineError = true;
            }
          }

          //  si erreur sur la ligne alors on affiche "OK" ou "Cancel" dans la colonne ERREUR générer //
          if (col === conf.VOR[0].columnNumber + 1 && lineError) {
            $(td).html('<i class="fas fa-window-close">Erreur</i>');
            $(td).css('color', 'red');
            $(td).attr('bgcolor', '#E6B0AA');
            lineError = false;
          } else if (col === conf.VOR[0].columnNumber + 1 && !lineError) {
            $(td).html('<i class="fas fa-check">Ok</i>');
            $(td).css('color', 'green');
            $(td).attr('bgcolor', '#28B463');
          }
          i++;
          line = row;
        }
      }
      ]
    };
  }

  checkVin(vin: string) {
    const regex = /^[A-Z]{2}[A-Z0-9]{15}$/g;
    const found = vin.match(regex);
    return found !== null ? true : false;
  }

  checkDate(date: string) {
    return moment(date, 'DD/MM/YYYY', true).isValid();
  }

  checkIsEmpty(isEmpty: string) {
    return (isEmpty.length === 0 || isEmpty === '' || isEmpty === undefined) ? true : false;
  }

  checkTd(b: boolean, id: number) {
    if (b) {
      $('#' + id).css('color', 'green');
      $('#' + id).attr('bgcolor', '#A9DFBF');
      $('#' + id).attr('color', 'green');
      this.checkLine($('#' + id));
    } else {
      $('#' + id).css('color', 'red');
      $('#' + id).attr('bgcolor', '#E6B0AA');
      $('#' + id).attr('color', 'red');
      this.checkLine($('#' + id));
    }
  }

  checkLine(cell: JQuery<HTMLElement> | { parentElement: { children: any[]; }; }[]) {
    let lineOk = true;
    for (let j = 1; j < cell[0].parentElement.children.length - 1; j++) {
      const check = $(cell[0].parentElement.children[j]).attr('color');
      if (check === 'red') {
        lineOk = false;
      }
    }
    const lastColIndex = this.records[0].length - 1;
    const checkTd = $(cell[0].parentElement.children[lastColIndex]);
    if (!lineOk) {
      checkTd.html('<i class="fas fa-window-close">Erreur</i>');
      checkTd.css('color', 'red');
      checkTd.attr('bgcolor', '#E6B0AA');
    } else {
      checkTd.html('<i class="fas fa-check">Ok</i>');
      checkTd.css('color', 'green');
      checkTd.attr('bgcolor', '#28B463');
    }
  }

  checkErrors(data: any) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < data.length; i++) {
      if (data[i] !== undefined) {
        return true;
      }
    }
    return false;
  }

  checkRowVor(data: any, line: number) {
    let check = false;
    for (let i = 0; i < data.length - 1; i++) {
      // Check VIN //
      if (i === 0) {
        if (!this.checkVin(data[i])) {
          this.errors.push('Ligne ' + line + ' colonne ' + (i + 1) + ' - VIN - Le VIN n\'est pas correct');
          check = true;
        }
      }
      // Check type carburant //
      if (i === 2 && this.checkIsEmpty(data[i])) {
        this.errors.push('Ligne ' + line + ' colonne ' + (i + 1) + ' - VEH_FUEL TYPE - Le type de carburant n\'est pas correct');
        check = true;
      }
      // Check Marque //
      if (i === 3 && this.checkIsEmpty(data[i])) {
        this.errors.push('Ligne ' + line + ' colonne ' + (i + 1) + ' - VEH_MAKE - La marque n\'est pas correct');
        check = true;
      }
      // Check origine //
      if (i === 4 && this.checkIsEmpty(data[i])) {
        this.errors.push('Ligne ' + line + ' colonne ' + (i + 1) + ' - VEH_ORIGINE - L\'origine n\'est pas correct');
        check = true;
      }
      // Check modele //
      if (i === 5 && this.checkIsEmpty(data[i])) {
        this.errors.push('Ligne ' + line + ' colonne ' + (i + 1) + ' - VEH_CODEMODELE - Le modèle du véhicule n\'est pas correct');
        check = true;
      }
      // Check Renter_code //
      if (i === 6 && this.checkIsEmpty(data[i])) {
        this.errors.push('Ligne ' + line + ' colonne ' + (i + 1) + ' - Le code Loueur n\'est pas correct');
        check = true;
      }

    }
    return check;
  }

  fileReset() {
    console.log('fileReset');
    this.csvReader.nativeElement.value = '';
    this.filename = '';
    if (this.records.length > 0) {
      this.ngOnDestroy();
    }
  }

  ngOnDestroy(): void {
    console.log('destroy');
    this.records = [];
    this.dtOptions = {};
    this.headers = [];
    const table = $('#datatable').DataTable({
      paging: false
    });
    table.destroy();
  }

  uploadListener($event: any): void {
    if (this.records.length > 0) {
      $('#csvReader').val = null;
      this.records = [];
      this.dtOptions = {};
      this.headers = [];
    }

    this.loading = true;
    const files = $event.srcElement.files;
    this.filename = files[0].name;
    this.size = files[0].size;
    if (this.isValidCSVFile(files[0])) {
      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (csvData as string).split(/\r\n|\n/);
        this.headers = this.getHeaderArray(csvRecordsArray);
        if (this.headers.length === this.conf.VOR[0].columnNumber + 2) {
          this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, this.headers.length);
          this.dtOptions.data = this.records;
          this.dtTrigger.next();
          this.loading = false;
        } else {
          alert('Impossible d\'importer le fichier sélectionné: fichier invalide!');
          this.fileReset();
          this.loading = false;
        }
      };

      reader.onerror = () => {
        console.log('Une erreur est survenue lors du chargement du fichier!');
        this.loading = false;
      };

    } else {
      alert('Merci d\'iporter un fichier csv valide.');
      this.fileReset();
      this.loading = false;
    }
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    const csvArr = [];
    let check = false;
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const csvRecord = [];
      const currentRecord = (csvRecordsArray[i] as string).split(this.separator);
      // Check data //
      check = this.checkRowVor(currentRecord, i);
      if (currentRecord.length + 2 === headerLength) {

        for (let j = 0; j < currentRecord.length; j++) {
          // Add colonne counter for line column
          if (j === 0) {
            csvRecord.push(i);
            csvRecord.push(currentRecord[j].trim());
          } else {
            if (j === 1) {
              csvRecord.push(environment.conf_Hyundai.VOR[1].column[1].default);
            } else {
              csvRecord.push(currentRecord[j].trim());
            }
          }

          if (currentRecord.length === (j + 1)) {
            if (check) {
              csvRecord.push(1);
            } else {
              csvRecord.push(0);
            }
          }
        }
        csvArr.push(csvRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  getHeaderArray(csvRecordsArr: any) {
    const headers = (csvRecordsArr[0] as string).split(this.separator);
    const headerArray = [];
    let j = 0;
    for (const h of headers) {
      // Add column Line
      if (j === 0) {
        headerArray.push('Ligne');
        headerArray.push(h);
      } else {
        headerArray.push(h);
      }
      // Add colonne Erreur
      if (headers.length - 1 === j) {
        headerArray.push('Erreur');
      }
      j++;
    }
    return headerArray;
  }

  sendJson() {
    this.posted = JSON.stringify(this.records);

    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < this.records.length; j++) {
      this.loading = true;
      this.importService.importVor(this.records[j])
        .pipe(first())
        .subscribe(
          response => {
            this.responses[j] = response;
            this.loading = false;
            console.log(this.responses);
          },
          error => {
            this.loading = false;
            this.responses[j] = error;
            console.log(this.responses);
          });

    }

  }

  // Change value //
  onRowClick(event: { target: { _DT_CellIndex: { column: string | number; row: string | number; }; innerHTML: any; id: any; }; }) {

    const col = event.target._DT_CellIndex.column;
    const row = event.target._DT_CellIndex.row;
    const data = event.target.innerHTML;
    const id = event.target.id;

    switch (col) {
      // Check VIN //
      case 1:
        if (this.checkVin(data) === true) {
          this.checkTd(true, id);
        } else {
          this.checkTd(false, id);
        }
        break;
      // Check FUEL_TYPE
      case 2:
        if (this.checkIsEmpty(data) === false) {
          this.checkTd(true, id);
        } else {
          this.checkTd(false, id);
        }
        break;
      // Check Brand //
      case 3:
        if (this.checkIsEmpty(data) === false) {
          this.checkTd(true, id);
        } else {
          this.checkTd(false, id);
        }
        break;
      // Check ORIGIN //
      case 4:
        if (this.checkIsEmpty(data) === false) {
          this.checkTd(true, id);
        } else {
          this.checkTd(false, id);
        }
        break;
      // Check MODEL_CODE //
      case 5:
        if (this.checkIsEmpty(data) === false) {
          this.checkTd(true, id);
        } else {
          this.checkTd(false, id);
        }
        break;
      // Check RENTER_CODE //
      case 6:
        if (this.checkIsEmpty(data) === false) {
          this.checkTd(true, id);
        } else {
          this.checkTd(false, id);
        }
        break;
    }

    if (this.checkErrors(this.errors) === false) {
      this.send = true;
    }

    this.records[event.target._DT_CellIndex.row][event.target._DT_CellIndex.column] = event.target.innerHTML;
  }
}
