import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Vehicle } from 'src/app/models/vehicle';

@Injectable({
  providedIn: 'root'
})

export class ImportService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor( private httpClient: HttpClient ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }

   importVor(vehicle: Array<any>) {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    return this.httpClient.post<any>(environment.apiUrl + '/api/eval_vors',
      {
        vin: vehicle[environment.conf_Hyundai.VOR[1].column[0].index],
        fueltype : vehicle[environment.conf_Hyundai.VOR[1].column[1].index],
        brand : vehicle[environment.conf_Hyundai.VOR[1].column[2].index],
        origin : vehicle[environment.conf_Hyundai.VOR[1].column[3].index],
        modelcode : vehicle[environment.conf_Hyundai.VOR[1].column[4].index],
        rentercode : vehicle[environment.conf_Hyundai.VOR[1].column[5].index],
        firstregistration : vehicle[environment.conf_Hyundai.VOR[1].column[6].index],
        finition :  vehicle[environment.conf_Hyundai.VOR[1].column[7].index],
        platenumber : vehicle[environment.conf_Hyundai.VOR[1].column[8].index],
        model : vehicle[environment.conf_Hyundai.VOR[1].column[9].index],
        customername : vehicle[environment.conf_Hyundai.VOR[1].column[2].index],
      },
      { headers: header}
      );
  }

  importPark(vehicle: Array<any>) {
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json');
    return this.httpClient.post<any>(environment.apiUrl + '/app/import/parkentry',
      {
        VIN: vehicle[environment.conf_Hyundai.PARK[1].column[0].index],
        DATE_PARC: vehicle[environment.conf_Hyundai.PARK[1].column[1].index],
        CODE_PARC: vehicle[environment.conf_Hyundai.PARK[1].column[2].index],
        IMMATRICULATION : vehicle[environment.conf_Hyundai.VOR[1].column[3].index],
        KILOMETRAGE : vehicle[environment.conf_Hyundai.PARK[1].column[4].index],
        BOXAGE : vehicle[environment.conf_Hyundai.PARK[1].column[5].index],
      },
       { headers: header }
      );
  }

}
