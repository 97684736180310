export const nissan = {

  columnNumber: 13,
  column :
  [
    {VIN : {type : 'string', length: 17}},
    {FIRST_REGISTRATION : {type: 'DD/MM/YYYY', length: 10}},
    {FUEL_TYPE : {type : 'string'}},
    {FINITION : {type : 'string'}},
    {PLATE_NUMBER : {type : 'string'}},
    {BRAND : {type: 'string'}},
    {MODEL : {type : 'string'}},
    {ORIGIN : {type: 'string'}},
    {TYPE : {type: 'string'}},
    {BRAND_CODE : {type: 'string'}},
    {MODEL_CODE : {type: 'string'}},
    {RENTER_CODE : {type: 'string'}}
  ]
};
