export const hyundai = {
  client : 'HYUNDAI',
  VOR :
  [
    {columnNumber: 10},
    {column:
      [
        {label : 'vin', type : 'string', length: 17, index: 1, mandatory : true, example : 'NLHA851CAJZ435818'},
        {label : 'fuelType' , type : 'string', index: 2, mandatory : true, example : 'ESSENCE', default : 'NC'},
        {label : 'brand' , type: 'string', index: 3, mandatory : true, example : 'HYUNDAI'},
        {label : 'origin' , type: 'string', index: 4, mandatory : true, example : 'LCD'},
        {label : 'modelCode' , type: 'string', index: 5, mandatory : true, example : 'B9S6K2615GGAW9'},
        {label : 'renterCode' , type: 'string', index : 6, mandatory : true, example : '39218'},
        {label : 'firstRegistration' , type: 'DD/MM/YYYY', length: 10, index: 7, mandatory : false, example : '20/04/2018'},
        {label : 'finition' , type : 'string', index : 8, mandatory : false, example : '1.2 87 BVM5 Edition #Mondial'},
        {label : 'plateNumber' , type : 'string', index : 9, mandatory : false, example : 'EW 968 ZS'},
        {label : 'model' , type : 'string', index : 10, mandatory : false, example : 'I20 NG'}
      ]},
  ],
  PARK :
  [
    {columnNumber: 6},
    {column:
      [
        {label : 'vin', type : 'string', length: 17, index: 1, mandatory : true, example : 'TMAJ3817AKJ865584'},
        {label : 'parkDate' , type: 'DD/MM/YYYY', length: 10, index: 2, mandatory : true, example : '07/11/2019'},
        {label : 'parkCode', type : 'string', index: 3, mandatory : true, example : '241CECL'},
        {label : 'plateNumber', type : 'string', index: 4, mandatory : true, example : 'FG-396-BP'},
        {label : 'kilometers', type : 'integer', index: 5, mandatory : false, example : '17372'},
        {label : 'box', type : 'string', index: 6, mandatory : false, example : 'TP220'}
      ]},
  ],
};
