import { Component } from '@angular/core';
@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent {
  fileType: string;
  selectedFileType(fileType) {
    this.fileType = fileType;
  }
}
