// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environment as prod } from './environment.prod';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { hyundai } from '../config/hyundai';
import { nissan } from '../config/nissan';
import { common_wording } from '../config/common';

export const environment = {
  production: false,
  apiUrl: 'https://api.eval.sgs.com',
  //apiUrl: 'https://pp.api.eval.sgs.com',
  //apiUrl:  'https://localhost:8000',
  version: prod.version,
  conf_Hyundai: hyundai,
  conf_Nissan : nissan,
  common : common_wording
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone r}elated error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
