import { hyundai } from '../config/hyundai';
import { nissan } from '../config/nissan';

export const environment = {
  production: true,
  apiUrl: 'https://api.eval.sgs.com',
  version: 'V.001',
  conf_Hyundai: hyundai,
  conf_Nissan : nissan
};
